.navbar-nav {
    gap: 30px;
    display: flex;
    align-items: center;

    li a{
        text-decoration: none;
        color: inherit;

        &:hover{
            filter: opacity(0.5);
        }
    }

    li.nav-item.active {
        font-weight: 700;
        background-color: black;
        color: white;
        padding-left: 10px;
    }

    @media screen and (max-width: 768px){
        gap: 0px;

        li {
            height: 40px;
            display: flex;
            align-items: center;
        }
    }
}

.navbar-collapse {
    max-width: 700px;
}

.navbar > .container-fluid {
    justify-content: space-evenly;
}

.dropdown-menu.show {
    padding: 20px;

    ul{
        flex-direction: column;
    }

    li.nav-item{
        margin-bottom: 5px;
    }
}

.align-right{
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.separator {
    width: 100%;
    height: 20px;
}

.formImage {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 300px;
    position: relative;

    img {
        width: 100%;
    }

    .remove {
        position: absolute;
        top: 0;
        right: 0;
    }
}

input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}